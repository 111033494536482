import * as React from "react";
import Redirect from "../../components/redirect";
//--------------------------------------------------

  // original url : https://yucolab-hongkong.slack.com/archives/C02MGAJPBLJ/p1637054930001000
  //const url = "https://bit.ly/34w55LQ";
  const url = "https://yucolab-hongkong.slack.com/archives/C02MGAJPBLJ/p1637054930001000";

//--------------------------------------------------
// common config below this line, no need to change
const IndexPage = () => ( <Redirect goto_url={url} /> );
export default IndexPage;
